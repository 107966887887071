import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { BackendQueryKeys } from '@oresundsbron/api';
import {
  chain,
  fromNullable,
  getOrElse,
  map,
  fromPredicate,
  alt,
  fromOption,
} from 'fp-ts/lib/TaskOption';
import { map as mapT, of } from 'fp-ts/lib/Task';
import { apply, flow, pipe } from 'fp-ts/lib/function';
import { constants, customerParser } from '@oresundsbron/validators';
import { head } from 'fp-ts/lib/Array';
import { z } from 'zod';
import { Lens } from 'monocle-ts';
import { useQueryClient } from '@tanstack/react-query';
import * as B from 'fp-ts/boolean';

type CustomerInformation = z.output<typeof customerParser>;

const getCustomerType = Lens.fromProp<CustomerInformation>()('customerType');

const strMatcher =
  <T extends string, R>(adts: Record<T, () => R>) =>
  (v: T) =>
    adts[v]();

const getFirstContract = (customerInformation: CustomerInformation) =>
  pipe(customerInformation.contracts, head, fromOption);

const getUrlForBusiness = () => (customerInformation: CustomerInformation) =>
  pipe(
    customerInformation,
    fromPredicate(({ contracts }) => contracts.length > 1),
    map(() => '/account/dashboard'),
    alt(() =>
      pipe(
        customerInformation,
        getFirstContract,
        map(({ contractNo }) => `/account/dashboard`)
      )
    )
  );

const getUrlForUsersWithOnlyOneContract = () =>
  flow(
    getFirstContract,
    map(({ contractNo }) => `/account/dashboard`)
  );

const getRedirectUrl = (customerInformation: CustomerInformation) =>
  pipe(
    customerInformation,
    getCustomerType.get,
    strMatcher({
      [constants.customerType.privateCustomer]:
        getUrlForUsersWithOnlyOneContract,
      [constants.customerType.businessCustomer]: getUrlForBusiness,
      [constants.customerType.businessCommuter]:
        getUrlForUsersWithOnlyOneContract,
    }),
    apply(customerInformation)
  );

export function useRedirect() {
  const { push } = useRouter();
  const queryClient = useQueryClient();

  const redirect = useCallback(
    (loginRedirect?: string) =>
      pipe(
        !!loginRedirect,
        B.match(
          () =>
            pipe(
              queryClient.getQueryData(
                BackendQueryKeys.CustomerInformation()
              ) as CustomerInformation | undefined,
              fromNullable,
              chain(getRedirectUrl),
              getOrElse(() => of('/account/dashboard'))
            ),
          () => of(loginRedirect ?? '/account/dashboard')
        ),
        mapT(push)
      )() as unknown as Promise<void>,
    [push, queryClient]
  );

  return redirect;
}
